import { useImageUri } from "../../api/images/ImageAPI";
import PropTypes from "prop-types";
import "./ImageDisplay.css";

ImageDisplay.propTypes = {
  image: PropTypes.object,
};

export function ImageDisplay(props) {
  const { image } = props;

  if (image?.type === "PROJECT") {
    return (
      <ImageDisplayApi imageId={image.image} type={image.type} {...props} />
    );
  } else {
    // For Legacy Support
    return <ImageDisplayUri imageUri={image} {...props} />;
  }
}

ImageDisplayUri.propTypes = {
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  imageUri: PropTypes.string,
  borderRadius: PropTypes.string,
  isBezierDisabled: PropTypes.bool,
};

function ImageDisplayUri(props) {
  const { imageWidth, imageHeight } = props;
  const { imageUri } = props;
  const { borderRadius } = props;
  const { isBezierDisabled } = props;

  let classType = "image-display-bezier";

  if (isBezierDisabled) {
    classType = "image-display";
  }

  return (
    <div
      className={classType}
      style={{
        height: imageHeight,
        width: imageWidth,
        backgroundImage: `url(${imageUri})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(248, 247, 216, 0.7)",
        borderRadius: borderRadius || "0px",
      }}
    ></div>
  );
}

ImageDisplayApi.propTypes = {
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  imageId: PropTypes.string,
  type: PropTypes.string,
  borderRadius: PropTypes.string,
  isBezierDisabled: PropTypes.bool,
};

function ImageDisplayApi(props) {
  const { imageWidth, imageHeight } = props;
  const { borderRadius } = props;
  const { isBezierDisabled } = props;

  const { imageId } = props;
  const { type } = props;

  const { imageUri } = useImageUri(imageId, type);

  let classType = "image-display-bezier";

  if (isBezierDisabled) {
    classType = "image-display";
  }

  return (
    <div
      className={classType}
      style={{
        height: imageHeight,
        width: imageWidth,
        backgroundImage: `url(${JSON.stringify(imageUri?.data)})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(248, 247, 216, 0.7)",
        borderRadius: borderRadius || "0px",
      }}
    ></div>
  );
}
